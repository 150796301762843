/* eslint-disable prettier/prettier */

import { tokens } from '@/locales/tokens/index'

export const fr = {
  [tokens.common.welcome]: 'Bienvenue',
  [tokens.common.welcomeLogin]: 'Bienvenue sur Nabu',
  [tokens.common.welcomeLoginText]:
    "Nabu est le copilote douane qui vous permet d'accéder, de gérer et d'exécuter efficacement vos formalités et données douanières.",
  [tokens.common.login]: 'Se connecter',
  [tokens.common.loginError]:
    "Oups, il semble que l'email ou le mot de passe que vous avez saisi soit incorrect. Veuillez revérifier et réessayer.",
  [tokens.common.emailSendError]:
    "Oups, il semble que l'envoi d'email n'a pas fonctionné. Veuillez recommencer.",
  [tokens.common.returnToLoginPage]: 'Retourner à la page de connexion',
  [tokens.common.logout]: 'Se déconnecter',
  [tokens.common.noAccount]: "Vous n'avez pas de compte ?",
  [tokens.common.questionForgotPassword]: 'Mot de passe oublié ?',
  [tokens.common.forgotPassword]: 'Mot de passe oublié',
  [tokens.common.passwordConfirmation]: 'Confirmer le mot de passe',
  [tokens.common.forgotPasswordText]:
    'Entrez l’adresse e-mail associée à votre compte',
  [tokens.common.enterNewPassword]: 'Configurer un nouveau mot de passe',
  [tokens.common.emailSent]: 'Un email vous a été envoyé',
  [tokens.common.contactUs]: 'Contactez-nous',
  [tokens.common.confirm]: 'Confirmer',
  [tokens.common.validate]: 'Valider',
  [tokens.common.delete]: 'Supprimer',
  [tokens.common.noOptions]: 'Aucune option',
  [tokens.common.cancel]: 'Annuler',
  [tokens.common.errorRenameFlowName]: "Le nom du flux n'a pas pu être renommé",
  [tokens.common.next]: 'Suivant',
  [tokens.common.continue]: 'Continuer',
  [tokens.common.back]: 'Retour',
  [tokens.common.close]: 'Fermer',
  [tokens.common.create]: 'Créer',
  [tokens.common.upload]: 'Upload',
  [tokens.common.sentDocuments]: 'Documents envoyés',
  [tokens.common.sentFolders]: 'Dossiers envoyés',
  [tokens.common.tableConsolidated]: 'Tableau consolidé',
  [tokens.common.tableNotConsolidated]: 'Tableau non consolidé',
  [tokens.common.numberofLinesSent]: 'Nombre total de lignes envoyées :',
  [tokens.common.send]: 'Envoyer',
  [tokens.common.add]: 'Ajouter',
  [tokens.common.languageChanged]: 'Langue modifiée',
  [tokens.common.manager]: 'Manager',
  [tokens.common.guest]: 'Invité',
  [tokens.common.admin]: 'Administrateur',
  [tokens.common.user]: 'Utilisateur',
  [tokens.common.superadmin]: 'Staff Nabu',
  [tokens.common.mail]: 'Adresse email',
  [tokens.common.email]: 'Email',
  [tokens.common.role]: 'Rôle',
  [tokens.common.date]: 'Date',
  [tokens.common.name]: 'Nom',
  [tokens.common.member]: 'Membre',
  [tokens.common.members]: 'Membres',
  [tokens.common.administrator]: 'Administrateur',
  [tokens.common.administrators]: 'Administrateurs',
  [tokens.common.sendInvite]: 'Envoyer une invitation',
  [tokens.common.inviteMember]: 'Inviter un membre',
  [tokens.common.inviteMembers]: 'Inviter des membres',
  [tokens.common.inviteAdministrator]: 'Inviter un administrateur',
  [tokens.common.inviteAdministrators]: 'Inviter des administrateurs',
  [tokens.common.atLeastOneInvitedPeople]: 'Inviter au moins une personne',
  [tokens.common.maxOneInvitedPeople]: 'Inviter maximum un admin',
  [tokens.common.inviteSomeAdministrators]: 'Inviter un administrateur',
  [tokens.common.sendInvitation]: 'Envoyer une invitation',
  [tokens.common.deleteInvitation]: "Supprimer l'invitation",
  [tokens.common.socialName]: 'Raison sociale',
  [tokens.common.registrationNumber]: 'Numéro de Siret',
  [tokens.common.country]: 'Pays',
  [tokens.common.organization]: 'Organisation',
  [tokens.common.organizationAndEstablishments]: 'Organisation & Etablissement',
  [tokens.common.somethingWentWrong]: "Quelque chose n'a pas fonctionné...",
  [tokens.common.general]: 'Général',
  [tokens.common.lastName]: 'Nom',
  [tokens.common.firstName]: 'Prénom',
  [tokens.common.function]: 'Fonction',
  [tokens.common.preferences]: 'Préférences',
  [tokens.common.password]: 'Mot de passe',
  [tokens.common.position]: 'Fonction',
  [tokens.common.language]: 'Langue',
  [tokens.common.help]: 'Aide',
  [tokens.common.active]: 'Actif',
  [tokens.common.group]: 'Groupe',
  [tokens.common.groups]: 'Groupes',
  [tokens.common.collaborators]: 'Collaborateurs',
  [tokens.common.update]: 'Modifier',
  [tokens.common.actions]: 'Actions',
  [tokens.common.parameters]: 'Paramètres',
  [tokens.common.external]: 'Extérieur',
  [tokens.common.validEmail]: 'Doit être un email valide',
  [tokens.common.required]: 'nécessaire',
  [tokens.common.emailRequired]: "L'email est obligatoire",
  [tokens.common.passwordRequired]: 'Mot de passe obligatoire',
  [tokens.common.matchPasswordsRequired]:
    'Les mots de passe doivent correspondre',
  [tokens.common.passwordConfirmationRequired]:
    'Confirmez votre nouveau mot de passe',
  [tokens.common.oldPasswordRequired]: "L'ancien mot de passe est requis",
  [tokens.common.newPasswordRequired]: 'Un nouveau mot de passe est requis',
  [tokens.common.warningRulesForNewPassword]:
    'Le mot de passe doit contenir au moins une lettre minuscule, une lettre majuscule, un chiffre, un caractère spécial (@, $, !, %, *, ?, &) et être entre 8 et 16 caractères.',
  [tokens.common.firstNameRequired]: 'Le prénom est requis',
  [tokens.common.lastNameRequired]: 'Le nom de famille est requis',
  [tokens.common.roleRequired]: 'Le rôle est requis',
  [tokens.common.languageRequired]: 'La langue est requise',
  [tokens.common.organizationRequired]: "L'organisation est requise",
  [tokens.common.groupRequired]: 'Le groupe est requis',
  [tokens.common.socialNameRequired]: 'Le nom social est requis',
  [tokens.common.registrationNumberRequired]:
    "Le numéro d'enregistrement est requis",
  [tokens.common.countryRequired]: 'Le pays est requis',
  [tokens.common.affiliatedGroup]: 'Sélectionner les groupes à affilier',
  [tokens.common.share]: 'Partager',
  [tokens.common.registeredCustomsRepresentative]:
    'Représentant des Douanes Enregistré',
  [tokens.common.customsManager]: 'Responsable des Douanes',
  [tokens.common.customsTeamManager]: "Responsable de l'équipe des Douanes",
  [tokens.common.informationSystemsManager]:
    "Responsable des Systèmes d'Information",
  [tokens.common.nabuAdministrator]: 'Administrateur de Nabu',
  [tokens.common.french]: 'Français',
  [tokens.common.english]: 'Anglais',
  [tokens.common.administrator]: 'Administrateur',
  [tokens.common.basicInformation]: 'Informations de base',
  [tokens.common.personalInformation]: 'Information personelle',
  [tokens.common.companyInformation]: 'Information entreprise',
  [tokens.common.organizationAndGroups]: 'Organisation et Groupes',
  [tokens.common.establishmentsAndGroups]: 'Établissements & groupes',
  [tokens.common.settings]: 'Paramètres',
  [tokens.common.save]: 'Enregistrer',
  [tokens.common.generalInformation]: 'Informations Générales',
  [tokens.common.offices]: 'Etablissements',
  [tokens.common.edit]: 'Editer',
  [tokens.common.accessInformations]: 'Accéder aux informations',
  [tokens.common.changePassword]: 'Changement de mot de passe',
  [tokens.common.oldPassword]: 'Ancien mot de passe',
  [tokens.common.newPassword]: 'Nouveau mot de passe',
  [tokens.common.passwordChangeSuccess]: 'Changement de mot de passe réussi',
  [tokens.common.fileName]: 'Nom de dossier',
  [tokens.common.deleteAccount]: 'Supprimer le compte',
  [tokens.common.deleteAccountWarning]:
    'La suppression de votre compte et de toutes vos données est irréversible.',
  [tokens.common.name]: 'Nom',
  [tokens.common.client]: 'Client',
  [tokens.common.date]: 'Date',
  [tokens.common.status]: 'Statut',
  [tokens.common.actions]: 'Actions',
  [tokens.common.option10]: '10',
  [tokens.common.option20]: '20',
  [tokens.common.option50]: '50',
  [tokens.common.searchByCaseName]: 'Rechercher par nom de dossier',
  [tokens.common.all]: 'Tous',
  [tokens.common.pending]: 'Demandes',
  [tokens.common.from]: 'Du',
  [tokens.common.to]: 'À',
  [tokens.common.view]: 'Vue',
  [tokens.common.folder]: 'Dossier',
  [tokens.common.customDeclaration]: 'Déclaration Douanière',
  [tokens.common.billOfLading]: 'Bill of Lading',
  [tokens.common.exported]: 'Exporter',
  [tokens.common.toTreat]: 'A traiter',
  [tokens.common.archived]: 'Supprimé',
  [tokens.common.inProgress]: 'En cours',
  [tokens.common.classification]: 'Classification',
  [tokens.common.category]: 'Catégorie',
  [tokens.common.searchByName]: 'Rechercher un dossier',
  [tokens.common.layerName]: 'Nom du flux',
  [tokens.common.flowName]: 'Flux Client',
  [tokens.common.flowSearchPlaceholder]: 'Rechercher un flux',
  [tokens.common.flowTabName]: 'Nom du flux',
  [tokens.common.flowTabType]: 'Type de flux',
  [tokens.common.declarationType]: 'Type Décl.',
  [tokens.common.flowTabExporter]: 'Expéditeur',
  [tokens.common.flowTabCreatedBy]: 'Crée par',
  [tokens.common.flowTabDate]: 'Date de création',
  [tokens.common.flowDeleteOneTitle]: "Suppresion d'un flux",
  [tokens.common.flowDeleteOneText]:
    'Êtes-vous sûr de vouloir supprimer ce flux ?',
  [tokens.common.flowDeleteMultipleTitle]: 'Suppresion de plusieurs flux',
  [tokens.common.flowDeleteMultipleText]:
    'Êtes-vous sûr de vouloir supprimer ces flux ?',
  [tokens.common.flowHasBeenDeleted]: 'Le flux a été supprimé',
  [tokens.common.flowHasNotBeenDeleted]: "Le flux n'a pas été supprimé",
  [tokens.common.teamName]: "Nom de l'équipe",
  [tokens.common.office]: 'Etablissement',
  [tokens.common.declaration]: 'Déclaration',
  [tokens.common.loggedIn]:
    'Bienvenue à nouveau ! Vous vous êtes connecté avec succès.',
  [tokens.common.permissionError]:
    "Désolé, vous n'avez pas la permission d'accéder à cette page.",
  [tokens.common.permissionAndAccess]: 'Permission/Accès',
  [tokens.common.fieldNotFound]: 'Champ non trouvé',
  [tokens.common.failToSave]: "Echec d'enregistrement",
  [tokens.common.team]: 'Equipe',
  [tokens.common.profil]: 'Profil',
  [tokens.common.security]: 'Sécurité',
  [tokens.common.metrics]: 'Suivi de performance',
  [tokens.common.copiedToClipBoard]: 'Copié dans le presse-papiers',
  [tokens.common.mailHook]: 'Mail Hook',
  [tokens.common.regenerateMailHook]: 'Regénérer le mail hook',
  [tokens.common.mailHookDescription]:
    'Adresse e-mail Nabu connectée à la boîte de réception de dossier de votre équipe',
  [tokens.common.copy]: 'copier',
  [tokens.common.setup]: 'Configuration',
  [tokens.common.setups]: 'Configurations',
  [tokens.common.addSetup]: 'Ajouter une configuration',
  [tokens.common.noSetup]: 'Aucune configuration disponible',
  [tokens.common.noOptionAvailable]: 'Aucune option disponible',
  [tokens.common.noSetupGroup]:
    'Aucune configuration disponible pour ce groupe',
  [tokens.common.editSetup]: 'Editer une configuration',
  [tokens.common.createSetup]: 'Créer une configuration',
  [tokens.common.download]: 'Télécharger',
  [tokens.common.file]: 'Fichier',
  [tokens.common.files]: 'Fichiers',
  [tokens.common.regularCustomer]: 'Client régulier',
  [tokens.common.punctualCustomer]: 'Client ponctuel',
  [tokens.common.creationOfNewFolder]: "Création d'un nouveau dossier",
  [tokens.common.emailFrom]: 'Expéditeur',
  [tokens.common.workInProgress]: 'Dossier en cours',
  [tokens.common.document]: 'Document',
  [tokens.common.flow]: 'Flux',
  [tokens.common.information]: 'Informations',
  [tokens.common.exportConfiguration]: 'Configuration',
  [tokens.common.userSettings]: "Paramètres d'utilisateur",
  [tokens.common.confirmationRequired]: 'Confirmation requise',
  [tokens.common.rowsPerPage]: 'Lignes par page',
  [tokens.common.details]: 'Détails',
  [tokens.common.backToHome]: "Retour à l'accueil",
  [tokens.common.message401]: '401 : Authorisation requis',
  [tokens.common.message404]: '404 : Page non trouvée',
  [tokens.common.message500]: '500 : Erreur serveur',
  [tokens.common.messageErrorPage]:
    "Quelque chose étrange s'est passée.\nVeuillez essayer la navigation ou revenez à l'accueil.",
  [tokens.common.key]: 'Champ',
  [tokens.common.configurations]: 'Configurations',
  [tokens.common.userSettingsNoParameter]: 'Aucun paramètre disponible',
  [tokens.common.commonError]: "Une erreur s'est produite",
  [tokens.common.author]: 'Auteur',
  [tokens.common.noFiles]: 'Aucun fichier disponible',
  [tokens.common.notification]: 'Notification',
  [tokens.common.createdBy]: 'Créé par',
  [tokens.common.sendBy]: 'Envoyé par',
  [tokens.common.updateSucceeded]: 'Mise à jour réussie',
  [tokens.common.updateFailed]: 'Mise à jour échouée',
  [tokens.common.filesDropInputWrongFormat]:
    'Type de fichier non pris en charge !',
  [tokens.common.hintMessageFirstText]: 'Utilisez',
  [tokens.common.hintMessageSecondText]: 'Espace',
  [tokens.common.hintMessageThirdText]: 'pour ajouter un mot-clé,',
  [tokens.common.hintMessageFourthText]: 'pour rechercher une phrase exacte.',

  [tokens.configuration.configurationNameWarning]:
    'Le nom de la configuration est requis',
  [tokens.configuration.typeTransportWarning]: "Le type d'export est requis",
  [tokens.configuration.nameTransportWarning]: 'Le nom du logiciel est requis',
  [tokens.configuration.addressTransportWarning]:
    'Une URL ou une adresse FTP est requise.',
  [tokens.configuration.portTransportWarning]: 'Le port est requis.',
  [tokens.configuration.emailAuthenticationTransportWarning]:
    "L'email pour l'authentification est requis.",
  [tokens.configuration.passwordAuthenticationTransportWarning]:
    'Le mot de passe est requis.',
  [tokens.configuration.configurationSaved]: 'Configuration enregistrée',
  [tokens.configuration.configurationName]: 'Nom de la configuration',
  [tokens.configuration.softwareName]: 'Nom du logiciel',
  [tokens.configuration.typeTransport]: 'Méthode de transport',
  [tokens.configuration.addressTransport]: 'API URL ou SFTP',
  [tokens.configuration.portTransport]: 'Port',
  [tokens.configuration.emailAuthenticationTransport]: 'Email',
  [tokens.configuration.passwordAuthenticationTransport]: 'Mot de passe',

  [tokens.upload.dropText]: 'Déposez vos fichiers ici',
  [tokens.upload.declarationType]: 'Type de déclaration',
  [tokens.upload.exportService]: 'Logiciel de déclaration en douane',
  [tokens.upload.workflow]: 'Flux',
  [tokens.upload.emailHookText]: 'ou envoyez vos documents sur ',
  [tokens.upload.atLeastOneFileRequired]: 'Au moins un fichier est requis',
  [tokens.upload.warningOnNumberFilesMinimum]: 'Au moins un fichier est requis',
  [tokens.upload.warningOnNumberFilesMaximum]: 'Un seul fichier est attendu',
  [tokens.upload.requiredFiles]: 'Fichiers requis',
  [tokens.upload.requiredCustomerType]: 'Type de client est requis',
  [tokens.upload.requiredRecordName]: 'Nom de dossier requis',
  [tokens.upload.warningOnRecordNameLength]:
    'Le nom de dossier doit comporter moins de 32 caractères.',
  [tokens.upload.requiredExportConfiguration]: 'Logiciel de déclaration requis',
  [tokens.upload.requiredDeclarationType]: 'Type de déclaration requis',
  [tokens.upload.requiredFluxConfiguration]: 'Flux requis',
  [tokens.upload.requiredGroup]: 'Groupe requis',

  [tokens.documents.collaborators]: 'Collaborateurs',
  [tokens.documents.statusExported]: 'Envoyé',
  [tokens.documents.statusToRead]: 'A consulter',
  [tokens.documents.statusToTreat]: 'A traiter',
  [tokens.documents.statusToSend]: 'A envoyer',
  [tokens.documents.statusError]: 'Erreur',
  [tokens.documents.statusSent]: 'Envoyé',
  [tokens.documents.visualizeDocuments]: 'Visualiser les documents',
  [tokens.documents.downloadDeclarationXML]:
    'Télécharger la déclaration au format XML',
  [tokens.documents.downloadDetailValue]: 'Télécharger le détail de valeur',
  [tokens.documents.downloadOrginalDocuments]:
    'Télécharger les documents originaux',
  [tokens.documents.typeDocument]: 'Type de document',
  [tokens.documents.nameDocument]: 'Nom du document',
  [tokens.documents.statusExtraction]: 'Extraction',
  [tokens.documents.statusInProgress]: 'Chargement',
  [tokens.documents.statusArchived]: 'Archivé',
  [tokens.documents.statusUploaded]: 'Chargé',
  [tokens.documents.statusUnknown]: 'Inconnu',
  [tokens.documents.removeRecordTitle]: "Suppression d'un dossier",
  [tokens.documents.removeRecordsTitle]: 'Suppression de plusieurs dossiers',
  [tokens.documents.removeRecordText]:
    'Êtes vous sûr de vouloir supprimer ce dossier?',
  [tokens.documents.removeRecordsText]:
    'Êtes vous sûr de vouloir supprimer ces dossiers?',
  [tokens.documents.removePendingRecordTitle]:
    "Suppression d'un dossier en attente",
  [tokens.documents.removePendingRecordsTitle]:
    'Suppression de plusieurs dossiers en attente',
  [tokens.documents.removePendingRecordText]:
    'Êtes vous sûr de vouloir supprimer ce dossier en attente?',
  [tokens.documents.removePendingRecordsText]:
    'Êtes vous sûr de vouloir supprimer ces dossiers en attente?',
  [tokens.documents.documentHasBeenDeleted]: 'Le dossier a été supprimé',
  [tokens.documents.documentHasNotBeenDeleted]:
    "Le dossier n'a pas été supprimé",
  [tokens.documents.documentHasBeenUpdated]: 'Le dossier a été mis à jour',
  [tokens.documents.noDocumentFound]: 'Aucun dossier trouvé',

  [tokens.export.confirmExportTitle]: "Confirmation d'envoi",
  [tokens.export.confirmExportText]:
    'Êtes vous sûr de vouloir envoyer ce dossier?',
  [tokens.export.confirmExport]: 'Envoyer',
  [tokens.export.successToExport]: 'Le dossier a été envoyé avec succès',
  [tokens.export.notExtracted]: 'Non extrait',

  [tokens.teams.removeFromGroup]: 'Retirer du groupe',
  [tokens.teams.youHaveNoTeam]: "Vous n'avez pas d'équipe",
  [tokens.teams.createATeam]: 'Créer une équipe',
  [tokens.teams.noRole]: 'Aucun rôle',
  [tokens.teams.teamName]: "Nom de l'équipe",

  [tokens.nav.account]: 'Compte',
  [tokens.nav.managementConsole]: 'Console administration',
  [tokens.nav.analytics]: 'Analytics',
  [tokens.nav.auth]: 'Authentification',
  [tokens.nav.browse]: 'Browse',
  [tokens.nav.create]: 'Créer',
  [tokens.nav.customers]: 'Clients',
  [tokens.nav.dashboard]: 'Tableau de bord',
  [tokens.nav.gobackToDeclaration]: 'Retour aux dossiers en cours',
  [tokens.nav.database]: 'Base de données',
  [tokens.nav.customerFlow]: 'Centre de données',
  [tokens.nav.customer]: 'Client',
  [tokens.nav.flow]: 'Flux',
  [tokens.nav.backoffice]: "Panneau d'administration",
  [tokens.nav.details]: 'Détails',
  [tokens.nav.edit]: 'Editer',
  [tokens.nav.error]: 'Erreur',
  [tokens.nav.feed]: 'Feed',
  [tokens.nav.fileManager]: 'File Manager',
  [tokens.nav.forgotPassword]: 'Mot de passe oublié',
  [tokens.nav.list]: 'Liste',
  [tokens.nav.login]: 'Connection',
  [tokens.nav.layers]: 'Modèles de document',
  [tokens.nav.mail]: 'Mail',
  [tokens.nav.management]: 'Management',
  [tokens.nav.orderList]: 'Commande',
  [tokens.nav.overview]: 'Overview',
  [tokens.nav.organizations]: 'Organisations',
  [tokens.nav.pages]: 'Pages',
  [tokens.nav.postCreate]: 'Post Create',
  [tokens.nav.postDetails]: 'Post Details',
  [tokens.nav.postList]: 'Post List',
  [tokens.nav.pricing]: 'Pricing',
  [tokens.nav.productList]: 'Products',
  [tokens.nav.fieldList]: 'Dossiers',
  [tokens.nav.fieldToRead]: 'A consulter',
  [tokens.nav.fieldToManage]: 'A traiter',
  [tokens.nav.fieldDeleted]: 'Supprimés',
  [tokens.nav.pendingField]: 'En cours',
  [tokens.nav.fieldExported]: 'Envoyés',
  [tokens.nav.pendingDocuments]: 'Documents en cours',
  [tokens.nav.sentDocuments]: 'Documents envoyés',
  [tokens.nav.removedDocuments]: 'Documents supprimés',
  [tokens.nav.profile]: 'Profile',
  [tokens.nav.register]: 'Register',
  [tokens.nav.resetPassword]: 'Reset Password',
  [tokens.nav.connectors]: 'Connecteurs',
  [tokens.nav.users]: 'Utilisateurs',

  [tokens.activity.lastActivity]: 'Dernières activités',
  [tokens.activity.subTitleLastActivity]: 'Basé sur la période sélectionnée',
  [tokens.activity.seeSentDocuments]: 'Voir les dossiers envoyés',
  [tokens.activity.seeDocumentsToManage]: 'Voir les documents',
  [tokens.activity.seeDocuments]: 'Voir les dossiers',
  [tokens.activity.exportedDocuments]: 'Dossiers envoyés',

  [tokens.dashboard.batchImport]: 'Dossier',
  [tokens.dashboard.pendingRecords]: 'Dossiers en cours',
  [tokens.dashboard.recordsToManage]: 'Dossiers à traiter',
  [tokens.dashboard.exportedRecords]: 'Dossiers envoyés',
  [tokens.dashboard.seeRecords]: 'Voir les dossiers',
  [tokens.dashboard.seeExportedRecords]: 'Voir les exports',
  [tokens.dashboard.createOrganization]: 'Créer une organisation',
  [tokens.dashboard.organizationCreated]:
    "L'organisation a été créée avec succès",
  [tokens.dashboard.createOffice]: 'Créer un établissement',
  [tokens.dashboard.officeCreated]: "L'établissement a été créé avec succès",
  [tokens.dashboard.officeDeleted]:
    "L'établissement a été supprimé avec succès",
  [tokens.dashboard.userCreated]: "L'utilisateur a été créé avec succès",
  [tokens.dashboard.deleteOrganization]: "Supprimer l'organisation",
  [tokens.dashboard.deleteUser]: "Supprimer l'utilisateur",
  [tokens.dashboard.userUpdated]: "L'utilisateur a été modifié avec succès",
  [tokens.dashboard.organizationAndGroups]: 'Organisation et Groupes',
  [tokens.dashboard.selectGroups]: 'Sélectionner les groupes à affilier',
  [tokens.dashboard.inviteCollaborators]: 'Inviter des collaborateurs',
  [tokens.dashboard.externalCollaborator]: 'Collaborateur extérieur',
  [tokens.dashboard.removeCollaborator]: 'Retirer le collaborateur',
  [tokens.dashboard.removeOrganizationTitle]: 'Supprimer une organisation',
  [tokens.dashboard.removeOrganizationText]:
    'Voulez-vous vraiment supprimer cette organisation ?',
  [tokens.dashboard.removeUserTitle]: 'Supprimer un utilisateur',
  [tokens.dashboard.removeUserText]:
    'Voulez-vous vraiment supprimer cet utilisateur ?',
  [tokens.dashboard.sendInvitationEmailTitle]: 'Envoyer une invitation',
  [tokens.dashboard.sendInvitationEmailText]:
    'Etes vous sur de vouloir envoyer une invitation à cet utilisateur ?',
  [tokens.dashboard.manageDocuments]: 'Gérer les documents',
  [tokens.dashboard.dropFilesHere]: 'Déposez vos fichiers ici',
  [tokens.dashboard.useNewFolderButton]:
    'ou utilisez le bouton "Nouveau Dossier"',
  [tokens.dashboard.newFolderButton]: 'Nouveau Dossier',
  [tokens.dashboard.folderInformation]: 'Informations du dossier',
  [tokens.dashboard.folderName]: 'Nom du Dossier',
  [tokens.dashboard.customer]: 'Client',
  [tokens.dashboard.exportService]: 'Logiciel de déclaration en douane',
  [tokens.dashboard.typeDeclarationService]: 'Type de déclaration',
  [tokens.dashboard.addNewCustomer]: 'Ajouter un nouveau client',
  [tokens.dashboard.customerName]: 'Nom du client',
  [tokens.dashboard.createNewCustomer]: 'Créer un nouveau client',
  [tokens.dashboard.punctualCustomer]: 'Client ponctuel',
  [tokens.dashboard.regularCustomer]: 'Client régulier',
  [tokens.dashboard.theEmailWasCopiedToYourClipboard]:
    'Le mail a été copié dans le presse-papiers !',
  [tokens.dashboard.fastTrackFirstText]: 'Fast track',
  [tokens.dashboard.fastTrackSecondText]:
    "(passer l’étape de classification si votre seul document est une facture ou un document d'accompagnement export)",
  [tokens.dashboard.fastTrackTooltipText]:
    'Sélectionnez un type de déclaration',

  [tokens.backoffice.importLayer]: 'Importer un modèle de document',
  [tokens.backoffice.chooseLayerName]: 'Choisir un nom de modèle de document',
  [tokens.backoffice.createUser]: 'Créer un utilisateur',
  [tokens.backoffice.deleteUser]: 'Utilisateur supprimé avec succès',
  [tokens.backoffice.successSendEmailMessage]:
    "Email d'invitation envoyé avec succès",
  [tokens.backoffice.users]: 'Utilisateurs',
  [tokens.backoffice.deleteOrganization]:
    "L'organisation supprimée avec succès",

  [tokens.backoffice.selectDocumentType]: 'Sélectionner un type de document',
  [tokens.backoffice.onlyFirstPage]:
    'Seule la première page pourra être utilisée.',
  [tokens.backoffice.acceptedFormats]: 'Formats acceptés',
  [tokens.backoffice.maxSize]: 'Taille maximale',
  [tokens.backoffice.exportConfig]: 'Configurations',
  [tokens.backoffice.layerNameIsRequired]: 'Le nom du flux est obligatoire',
  [tokens.backoffice.documentTypeIsRequired]:
    'Le type de document est obligatoire',
  [tokens.backoffice.exportConfigurationIsRequired]:
    "La configuration d'envoie est requise",

  [tokens.organizations.organizationsTitle]: 'Organisations',
  [tokens.organizations.organizationTitle]: 'Organisation',

  [tokens.extraction.index]: 'Index',
  [tokens.extraction.chooseClassification]: 'Choisir une classification',
  [tokens.extraction.exceptions]: 'Exceptions',
  [tokens.extraction.editCells]: 'Editer cellules',
  [tokens.extraction.editGrid]: 'Editer grille',
  [tokens.extraction.deleteTable]: 'Supprimer tableau',
  [tokens.extraction.deleteTablePage]: 'Supprimer le tableau sur cette page',
  [tokens.extraction.deleteAllTablePages]: 'Supprimer les tableaux sur toutes les pages',
  [tokens.extraction.noExceptionsFound]: 'Aucune exception trouvée',
  [tokens.extraction.informations]: 'Informations',
  [tokens.extraction.products]: 'Articles',
  [tokens.extraction.missingProduct]: 'Article manquant',
  [tokens.extraction.addInformation]: 'Ajouter une information',
  [tokens.extraction.addElement]: 'Ajouter un élément',
  [tokens.extraction.customInformation]: 'Informations douanières',
  [tokens.extraction.documentInformation]: 'Document',
  [tokens.extraction.shippingInformation]: "Information d'expédition",
  [tokens.extraction.transactionDetails]: 'Détails de la transaction',
  [tokens.extraction.findField]: 'Chercher un champ',
  [tokens.extraction.cellTooSmall]: 'Cellule trop petit',
  [tokens.extraction.cellNotValid]: 'Cellule non valide',
  [tokens.extraction.groupByHSCode]: 'Grouper par HS Code',
  [tokens.extraction.doYouWantToGroupTokensByHSCode]:
    'Voulez-vous grouper par HS Code ?',
  [tokens.extraction.yes]: 'Oui',
  [tokens.extraction.no]: 'Non',
  [tokens.extraction.addData]: 'Ajouter des données',
  [tokens.extraction.addRow]: 'Ajouter rangée',
  [tokens.extraction.tableRows]: 'Lignes du tableau',
  [tokens.extraction.deleteRow]: 'Supprimer rangée',
  [tokens.extraction.invoices]: 'Factures',
  [tokens.extraction['commercial-invoice']]: 'Facture',
  [tokens.extraction.extraction]: 'Extraction',
  [tokens.extraction.nothingToShow]: 'Rien à afficher',
  [tokens.extraction.table]: 'Tableau',
  [tokens.extraction.field]: 'Champ',
  [tokens.extraction.tables]: 'Tableaux',
  [tokens.extraction.displayTheTable]: 'Afficher le tableau',
  [tokens.extraction.save]: 'Sauvegarder',
  [tokens.extraction.noOtherFormatsAvailable]: 'Aucun autre format disponible',
  [tokens.extraction.downloadTestFile]: 'Télécharger le fichier test',
  [tokens.extraction.exportTo]: 'Envoyer vers',
  [tokens.extraction.on]: 'sur',
  [tokens.extraction.completedFields]: 'Champs complétés',
  [tokens.extraction.workComplete]: 'Dossier complet',
  [tokens.extraction.newField]: 'Nouveau champ',
  [tokens.extraction.newTable]: 'Nouveau tableau',
  [tokens.extraction.duplicate]: 'Dupliquer',
  [tokens.extraction.mustBeString]: 'Doit être du texte',
  [tokens.extraction.areYouSureYouWantToCopyDeclaration]:
    'Etes-vous sûr de vouloir de dupliquer cette déclaration',
  [tokens.extraction.thereWasAnErrorCopyingTheDeclaration]:
    'Il y a eu une erreur lors de la duplication',
  [tokens.extraction.mustMatchFormatDateTime]: 'Doit avoir format date correct',
  [tokens.extraction.mustNotHaveMoreThanCharacters]:
    'Doit pas avoir plus que {{ count }} caractères',
  [tokens.extraction.mustNotHaveFewerThanCharacters]:
    'Doit pas avoir plus que {{ count }} caractères',
  [tokens.extraction.pleaseEnterBetweenXAndYCharacters]:
    'Veuillez entrer entre {{min}} et {{max}} caractères',
  [tokens.extraction.pleaseEnterExactlyXCharacters]:
    'Veuillez entrer exactement {{count}} caractères',
  [tokens.extraction.pleaseEnterAWholeNumber]:
    'Veuillez entrer un nombre entier',
  [tokens.extraction.updatingData]: 'Mise à jour des données',
  [tokens.extraction.mustBeInAlpha2]:
    'Doit être au format alpha-2. Exemple pour France ⇒ FR.',
  [tokens.extraction.doitTreAuFormatAlpha2ExemplePourFranceFr]:
    'Doit être au format alpha-2. Exemple pour France ⇒ FR.',
  [tokens.extraction.pleaseEnterExactly10CharactersInFormat]:
    'Veuillez entrer exactement 10 caractères au format JJ/MM/AAAA',
  [tokens.extraction.pleaseEnterANumber]: 'Veuillez entrer un nombre',
  [tokens.extraction.pleaseEnterAMaximumOf]:
    'Veuillez entrer un maximum de {{count}} caractères',
  [tokens.extraction.pleaseSelectFromTheGivenValues]:
    'Veuillez choisir parmi les options proposées',
  [tokens.extraction.pleaseEnterAValueThatConsistsOfLetters]:
    'Veuillez entrer une valeur composée de lettres (majuscules ou minuscules), de chiffres et de tirets, et ayant une longueur maximale de 35 caractères',
  [tokens.extraction.pleaseEnterAValidSiretNumber]:
    'Veuillez entrer un numéro SIRET',
  [tokens.extraction['18LettersAndNumbersMaximum']]:
    '18 lettres et chiffres maximum',
  [tokens.extraction.mustBeInteger]: 'Doit être un nombre',
  [tokens.extraction.mustBeNumber]: 'Doit être un chiffre',
  [tokens.extraction.createTable]: 'Créer un tableau',
  [tokens.extraction.loadingExtractionInformation]:
    'Nabu extrait les données de vos documents',
  [tokens.extraction.deleteColumn]: 'Supprimer la colonne',
  [tokens.extraction.loadingBackToDashboard]:
    'Vous pouvez retourner sur votre tableau de bord en attendant',
  [tokens.extraction.doYouWantToDeleteTable]:
    'Etes-vous certain de vouloir supprimer les tableaux de toutes les pages ? Toutes les données des tableaux - extraites et ajoutées manuellement - seront perdues.',
  [tokens.extraction.doYouWantToDeleteTableOnCurrentPage]:
    'Etes-vous certain de vouloir supprimer le tableau de cette page ? Toutes données du tableau - extraites et ajoutées manuellement - seront perdues.',
  [tokens.extraction.editTable]: 'Édition du tableau',
  [tokens.extraction.editCells]: 'Édition des cellules',
  [tokens.extraction.deleteTableOnlyInThePage]:
    'Suppression du tableau sur cette page',
  [tokens.extraction.deleteTableInAllPage]:
    'Suppression du tableau sur toutes les pages',
  [tokens.extraction.doYouWantToDeleteColumn]:
    'Etes-vous certain de vouloir supprimer cette colonne ? Toutes données de la colonne - extraites et ajoutées manuellement - seront perdues.',
  [tokens.extraction.doYouWantToDeleteColumnAndDeconsolidate]:
    'Etes-vous certain de vouloir supprimer cette colonne ? Toutes données de la colonne - extraites et ajoutées manuellement - seront perdues et les articles ne seront plus consolidés',
  [tokens.extraction.doYouWantToDeleteRow]:
    'Etes-vous certain de vouloir supprimer cette rangée ? Toutes données de la rangée - extraites et ajoutées manuellement - seront perdues.',
  [tokens.extraction.doYouWantToEditColumnAndDeconsolidate]:
    'Etes-vous certain de vouloir éditer cette rangée ? les articles ne seront plus consolidés',
  [tokens.extraction.addOriginOfArticles]: "l'origine",
  [tokens.extraction.addHSCodeOfArticles]: 'le HS Code',
  [tokens.extraction.addCodePrefOfArticles]: 'le code préférence tarifaire',
  [tokens.extraction.addOriginCountryCode]: "le Code de pays d'origine",
  [tokens.extraction.codePreferenceModalTitle]: 'Code Préférences',
  [tokens.extraction.codePreferenceCopy]: 'Le code du champ a bien été copié',
  [tokens.extraction.ofItems]: 'des articles',
  [tokens.extraction.add]: 'Ajouter',
  [tokens.extraction.emptyTable]: 'Le tableau est vide',
  [tokens.extraction.addToTable]: 'Ajouter au tableau',
  [tokens.extraction.addColumn]: 'Ajouter une colonne',
  [tokens.extraction.declarationState]: 'État de votre déclaration',
  [tokens.extraction.returnToDeclarations]: 'Retour aux dossiers',
  [tokens.extraction.updateDeclaration]: 'Modifier la déclaration',
  [tokens.extraction.declarationUploading]:
    'Votre déclaration est en cours de dépot',
  [tokens.extraction.declarationUploaded]: 'Le dossier a bien été transmis',
  [tokens.extraction.youWillBeRedirectedToWorkflowCreation]:
    'Vous allez être redirigé vers la création de flux dans 10s',
  [tokens.extraction.youWillBeRedirectedToTheDashboard]:
    'Vous allez être redirigé vers le dashboard dans 10s',
  [tokens.extraction.declarationUploadFailed]: "L'envoi du dossier a échoué",
  [tokens.extraction.checkDeclarationAndRetry]:
    'Veuillez vérifier les informations du dossier et réessayer',
  [tokens.extraction.checkDeclaration]:
    'Veuillez vérifier les informations du dossier',
  [tokens.extraction.missingValuesInColumn]:
    '{{count}} valeur(s) manquante(s) dans la colonne "{{column}}" pour effectuer ce calcul',
  [tokens.extraction.columnMissing]:
    'Il manque les valeurs de la colonne "{{column}}" pour effectuer ce calcul',
  [tokens.extraction.warningOriginX]:
    "l'origine x du tableau recouvre des cellules",
  [tokens.extraction.warningOriginY]:
    "l'origine y du tableau recouvre des cellules",
  [tokens.extraction.warningTouchingCellsOnX]:
    'la largeur se réduit trop, touchant les cellules existantes',
  [tokens.extraction.warningTouchingCellsOnY]:
    'la hauteur se réduit trop, touchant les cellules existantes',
  [tokens.extraction.warningBottomRowTooSmall]:
    'la ligne du bas est trop petite',
  [tokens.extraction.warningTopRowTooSmall]: 'la ligne du haut est trop petite',
  [tokens.extraction.warningTableLeavesPage]: 'le tableau sort de la page',
  [tokens.extraction.consolidate]: 'Consolider',
  [tokens.extraction.consolidated]: 'Consolidé',
  [tokens.extraction.sendingConfirmation]: "Confirmation d'envoi",
  [tokens.extraction.createWorkflowInfo]:
    "Cela vous permet de sauvegarder les informations de cette déclaration afin de pouvoir les réutiliser. Lors de la création d'un nouveau dossier, sélectionnez simplement un flux dans la liste pour appliquer les paramètres enregistrés.",
  [tokens.extraction.sendingFileQuestion]:
    'Êtes-vous sûr de vouloir envoyer cette déclaration ?',
  [tokens.extraction.saveWorkflowQuestion]:
    'Souhaitez vous enregistrer ce flux?',
  [tokens.extraction.createNewWorkflow]: 'Créer un nouveau flux',
  [tokens.extraction.redirectCreateWorkflow]:
    'Redirection vers la création du flux',
  [tokens.extraction.fluxFieldsAreFixed]:
    'Les informations ci-dessous ne sont pas modifiables. Vous pouvez uniquement ajouter ou retirer un ou des champs à votre flux.',
  [tokens.extraction.selectAllFields]: 'Selectionner tous les champs',
  [tokens.extraction.workflowDetail]: 'Identification du flux',
  [tokens.extraction.workflowCreation]: "Création d'un flux",
  [tokens.extraction.workflowCreated]: 'Flux créé',
  [tokens.extraction.workflowCreationFailed]: 'Echec création flux',
  [tokens.extraction.flowApplyChip]: 'Aucun flux appliqué',
  [tokens.extraction.glideHeaderSum]: 'Somme',
  [tokens.extraction.glideHeaderArticleNumber]: "Nombre d'articles",
  [tokens.extraction.checkFieldModalFirstTextWarning]: 'Attention !',
  [tokens.extraction.checkFieldModalFirstText]:
    'Mettre à jour ce champs remplacera toutes les valeurs pour "',
  [tokens.extraction.checkFieldModalFirstTextEnding]: '" dans votre tableau.',
  [tokens.extraction.checkFieldModalSecondText]:
    'Toutes vos valeurs actuelles seront perdues !',
  [tokens.extraction.checkFieldModalFieldUpdated]: 'Updated table',
  [tokens.extraction.checkFieldModalConsolidatedTitle]:
    'Consolidation des articles',
  [tokens.extraction.checkFieldModalConsolidatedFirstText]:
    'Vous allez envoyer votre déclaration sans avoir consolidé les articles.',
  [tokens.extraction.checkFieldModalConsolidatedSecondText]:
    "Souhaitez vous modifier votre déclaration ou l'envoyer sans consolider ?",
  [tokens.extraction.checkFieldModalConsolidatedUpdatedButton]:
    'Retourner à la déclaration',
  [tokens.extraction.checkFieldModalConsolidatedSendButton]:
    'Envoyer sans consolider',
  [tokens.extraction.checkFieldModalFieldUpdated]: 'Tableau mis à jour',
  [tokens.extraction.checkFieldModalDependenciesWarning]: 'Attention !',

  [tokens.layers.importLayerSource]: 'Importer modèle source',
  [tokens.layers.layerJson]: 'Modèle de document JSON',
  [tokens.layers.metadataJson]: 'Metadata JSON',
  [tokens.layers.import]: 'Importer',
  [tokens.layers.yourLayers]: 'Vos modèles',
  [tokens.layers.searchForALayer]: 'Chercher un modèle',
  [tokens.layers.doYouWantToDeleteTheLayer]:
    'Voulez-vous supprimer le modèle de document',
  [tokens.layers.prefillClassification]: 'Champ pré-rempli',
  [tokens.layers.prefillValue]: 'Valeur pré-remplie',
  [tokens.layers.createANewLayer]: 'Créer un nouveau modèle',
  [tokens.layers.chooseLayerName]: 'Choisir un nom pour le modèle de document',
  [tokens.layers.selectFlow]: 'Sélectionner un flux',
  [tokens.layers.selectDocumentType]: 'Choisir un type de document',
  [tokens.layers.uploadRestrictions]: 'Limites téléchargement',
  [tokens.layers.maxSize10MB]: 'Taille max 10Mo',
  [tokens.layers.selectFile]: 'Choisir un fichier',
  [tokens.layers.selectedFile]: 'Fichier choisi',
  [tokens.layers.addLayer]: 'Ajouter un modèle',
  [tokens.layers.createLayer]: 'Créer un modèle',
  [tokens.layers.importLayer]: 'Importer un modèle',
  [tokens.layers.exportLayer]: 'Exporter un modèle',
  [tokens.layers.deleteLayer]: 'Supprimer un modèle',
  [tokens.layers.editLayer]: 'Editer un modèle',
  [tokens.layers.deleteLayerShort]: 'Supprimer',
  [tokens.layers.editLayerShort]: 'Editer',
  [tokens.layers.extraction]: 'Extraction',
  [tokens.layers.postprocess]: 'Post-Process',
  [tokens.layers.data]: 'Données',
  [tokens.layers.addRule]: 'Ajouter une règle',
  [tokens.layers.rules]: 'Règles',
  [tokens.layers.noRules]: 'Aucune règle',
  [tokens.layers.fieldList]: 'Liste des champs',
  [tokens.layers.tables]: 'Tableaux',
  [tokens.layers.save]: 'Sauvegarder',
  [tokens.layers.prefillData]: 'Données de pré-remplissage',
  [tokens.layers.addPrefillData]: 'Ajouter une donnée de pré-remplissage',
  [tokens.layers.filter]: 'Filtrage',
  [tokens.layers.extract]: 'Extraction',
  [tokens.layers.postprocessApplyOn]: 'Appliquer sur',
  [tokens.layers.postprocessExtractFrom]: 'Extraire de',
  [tokens.layers.postprocessAddTo]: 'Ajouter à',
  [tokens.layers.column]: 'Colonne',
  [tokens.layers.newColumn]: 'Nouvelle colonne',
  [tokens.layers.flowName]: 'Nom du flux',
  [tokens.layers.layerName]: 'Nom du modèle de document',
  [tokens.layers.active]: 'Active',
  [tokens.layers.lastUpdate]: 'Dernière modification',
  [tokens.layers.actions]: 'Actions',
  [tokens.layers.sender]: 'Expéditeur',
  [tokens.layers.receiver]: 'Destinataire',
  [tokens.layers.documentType]: 'Type de document',

  [tokens.flows.sender]: 'Expéditeur',
  [tokens.flows.receiver]: 'Destinataire',
  [tokens.flows.flowType]: 'Type de flux',
  [tokens.flows.exportContext]: 'Contexte export',

  [tokens.segmentation.finishSegmentationStep]: 'Valider la segmentation',
  [tokens.segmentation.merge]: 'Fusionner',
  [tokens.segmentation.cut]: 'Couper',
  [tokens.segmentation.deletePage]: 'Supprimer la page',
  [tokens.segmentation.keep]: 'Garder',

  [tokens.settings.noDocumentsFound]: 'Aucun document trouvé',

  [tokens.floatinglabelform.save]: 'Sauvegarder',
  [tokens.floatinglabelform.apply]: 'Appliquer',
  [tokens.floatinglabelform.text]: 'Texte',

  [tokens.viewer.doYouWantToDeleteTheTable]: 'Voulez-vous supprimer la table',
  [tokens.viewer.apply]: 'Appliquer',
  [tokens.viewer.adjustTheDocument]: 'Ajuster le document',
  [tokens.viewer.selectedLabel]: 'Label séléctioné',
  [tokens.viewer.selectADocument]: 'Choisir un document',
  [tokens.viewer.applyALayer]: 'Appliquer flux',
  [tokens.viewer.newColumn]: 'Nouvelle colonne',
  [tokens.viewer.invoice]: 'Facture',

  [tokens.classification.manualLayer]: 'Manuel',
  [tokens.classification.searchClassificationType]:
    'Chercher un type de classification',
  [tokens.classification.outOf]: 'sur',
  [tokens.classification.validate]: 'Valider',
  [tokens.classification.unknownDocument]: 'document inconnu',
  [tokens.classification.unknownDocuments]: 'documents inconnus',
  [tokens.classification.addExtractionMode]: "Ajouter un modèle d'extraction",
  [tokens.classification.ignoreDocument]: 'Ignorer ce document',
  [tokens.classification.fieldRequired]: 'Ce champ est requis',
  [tokens.classification.includeDocument]: 'Inclure ce document',
  [tokens.classification.selectExtractionMode]: "Choisir un mode d'extraction",
  [tokens.classification.onlyInvoicesAllowed]:
    'Seules les factures ou les DAE sont acceptées',
  [tokens.classification.atLeastOneInvoiceorOneEAD]:
    'Au moins une facture ou un DAE est attendu(e)',
  [tokens.classification.someDocumentsWithoutClassification]:
    "Certains documents n'ont pas de classification",
  [tokens.classification.hasNoExportConfiguration]:
    'Le type de déclaration est manquant',
  [tokens.classification.document]: '{{count}} document',
  [tokens.classification.document_plural]: '{{count}} documents',
  [tokens.classification.page]: '{{count}} page',
  [tokens.classification.page_plural]: '{{count}} pages',
  [tokens.classification.MT700]: 'MT 700',
  [tokens.classification.weight_note]: 'Note de poids',
  [tokens.classification.exchange_bill]: 'Lettre de change',
  [tokens.classification.origin_certif]: "Certificat d'origine",
  [tokens.classification.multimodal_doc]: 'Document de transport multimodal',
  [tokens.classification.flight_letter]: 'Lettre de transport aérien',
  [tokens.classification.sea_waybill]: 'Connaissement maritime',
  [tokens.classification.road_document]: 'Document de transport routier',
  [tokens.classification.delivery_note]: 'Bon de livraison',
  [tokens.classification.insurance_policy]: "Police d'assurance",
  [tokens.classification.bill_of_lading]: 'Connaissement',
  [tokens.classification.single_administrative_document]: 'DAU',
  [tokens.classification.commercial_invoice]: 'Facture commerciale',
  [tokens.classification.customs_instructions]: 'Instructions douanières',
  [tokens.classification.packing_list]: 'Liste de colisage',
  [tokens.classification.electronic_accompanying_document]: 'DAE',
  [tokens.classification.EAD]: 'Export accompaning document',
  [tokens.classification.MT700_short]: 'MT700',
  [tokens.classification.weight_note_short]: 'Note de poids',
  [tokens.classification.exchange_bill_short]: 'Lettre de change',
  [tokens.classification.origin_certif_short]: 'CO',
  [tokens.classification.multimodal_doc_short]: 'DTM',
  [tokens.classification.flight_letter_short]: 'LTA',
  [tokens.classification.sea_waybill_short]: 'Connaissement',
  [tokens.classification.road_document_short]: 'DTR',
  [tokens.classification.delivery_note_short]: 'Bon de livraison',
  [tokens.classification.insurance_policy_short]: "Police d'assurance",
  [tokens.classification.bill_of_lading_short]: 'Connaissement',
  [tokens.classification.single_administrative_document_short]: 'DAU',
  [tokens.classification.commercial_invoice_short]: 'Facture',
  [tokens.classification.customs_instructions_short]: 'Instru. douanières',
  [tokens.classification.packing_list_short]: 'Colisage',
  [tokens.classification.electronic_accompanying_document_short]: 'DAE',
  [tokens.classification.EAD_short]: 'DAE',

  [tokens.connector.addConnector]: 'Ajouter un connecteur',

  [tokens.filter.sortBy]: 'Ordonner par',
  [tokens.filter.searchOrganization]: 'Rechercher une organisation',
  [tokens.filter.searchUser]: 'Rechercher un utilisateur',
  [tokens.filter.searchLayer]: 'Rechercher un modèle de document',
  [tokens.filter.lastUpdateNewest]: 'Dernière mise à jour (+ récent)',
  [tokens.filter.lastUpdateOldest]: 'Dernière mise à jour (-récent)',

  [tokens.customerFlow.customer]: 'Client',
  [tokens.customerFlow.flow]: 'Flux',
  [tokens.customerFlow.documentType]: 'Type de document',
  [tokens.customerFlow.removeCustomerTitle]: 'Supprimer un client',
  [tokens.customerFlow.removeCustomerText]:
    'Êtes vous sûr? Cette action est irréversible.',
  [tokens.customerFlow.customerHasBeenDeleted]: 'Le client a été supprimé',
  [tokens.customerFlow.customerHasNotBeenDeleted]:
    "Le client n'a pas été supprimé",
  [tokens.customerFlow.removeFlowTitle]: 'Supprimer un flux',
  [tokens.customerFlow.removeFlowText]:
    'Êtes vous sûr? Cette action est irréversible.',
  [tokens.customerFlow.flowHasBeenDeleted]: 'Le flux a été supprimé',
  [tokens.customerFlow.flowHasNotBeenDeleted]: "Le flux n'a pas été supprimé",
  [tokens.customerFlow.settingKeyDeleted]:
    'La clé de paramètre a été supprimée',
  [tokens.customerFlow.actionFailed]: "L'action a échoué",
  [tokens.customerFlow.settingKeyCreated]: 'La clé de paramètre a été créée',
  [tokens.customerFlow.settingKeyUpdated]:
    'La clé de paramètre a été mise à jour',
  [tokens.customerFlow.sender]: 'Expéditeur',
  [tokens.customerFlow.flowType]: 'Type de flux',
  [tokens.customerFlow.informations]: 'Informations',
  [tokens.customerFlow.documentModels]: 'Modèles de document',
  [tokens.customerFlow.nameOfModel]: 'Nom du modèle',
  [tokens.customerFlow.issuesSettingsOptions]: 'Erreur au chargement des clées',
  [tokens.customerFlow.noFluxAvailable]: 'Aucun flux disponible',
  [tokens.customerFlow.unknownUser]: 'Inconnu',

  [tokens.customer.addNew]: 'Ajouter un nouveau client',
  [tokens.customer.address]: 'Adresse',
  [tokens.customer.addressRequired]: "L'adresse est requise",
  [tokens.customer.basicInformation]: 'Informations de base',
  [tokens.customer.corporateName]: 'Raison sociale',
  [tokens.customer.corporateNameRequired]: 'La raison sociale est requise',
  [tokens.customer.city]: 'Ville',
  [tokens.customer.cityRequired]: 'La ville est requise',
  [tokens.customer.country]: 'Pays',
  [tokens.customer.countryRequired]: 'Le pays est requis',
  [tokens.customer.eoriNumber]: 'Numéro EORI',
  [tokens.customer.eoriNumberRequired]: 'Le numéro EORI est requis',
  [tokens.customer.identifiers]: 'Identifiants',
  [tokens.customer.postalCode]: 'Code postal',
  [tokens.customer.postalCodeRequired]: 'Le code postal est requis',
  [tokens.customer.rexNumber]: 'Numéro REX',
  [tokens.customer.rexNumberRequired]: 'Le numéro REX est requis',
  [tokens.customer.siretNumber]: 'Numéro SIRET',
  [tokens.customer.siretNumberRequired]: 'Le numéro SIRET est requis',
  [tokens.customer.societyType]: 'Type de société',
  [tokens.customer.societyTypeRequired]: 'Le type de société est requis',
  [tokens.customer.vatNumber]: 'Numéro TVA',
  [tokens.customer.vatNumberRequired]: 'Le numéro TVA est requis',
  [tokens.customer.otherSettings]: 'Autres paramètres',
  [tokens.customer.settingValueRequired]: 'Une valeur est requise',

  [tokens.managementConsole.title]: "Console d'administration",
  [tokens.managementConsole.establishmentsAndGroups]: 'Etablissement & Groupes',
  [tokens.managementConsole.metrics]: 'Suivi de performance',
  [tokens.managementConsole.member_one]: 'Membre',
  [tokens.managementConsole.member_other]: 'Membres',
  [tokens.managementConsole.members]: 'Membres',
  [tokens.managementConsole.general]: 'Général',
  [tokens.managementConsole.organizationSettings]: 'Paramètres',
  //General section
  [tokens.managementConsole.companyInformation]: 'Information entreprise',
  [tokens.managementConsole.companyNameRequired]: 'Raison sociale requise',
  [tokens.managementConsole.registrationNumberRequired]: 'Numéro siret requis',
  [tokens.managementConsole.registrationNumberWarning]:
    "Le numéro d'enregistrement doit avoir au moins 6 chiffres",
  [tokens.managementConsole.registrationCountryRequired]: 'pays requis',
  [tokens.managementConsole.registrationCountryWarning]:
    'Le pays doit avoir exactement 3 lettres',
  [tokens.managementConsole.organizationUpdated]: 'Organisation mise à jour',
  [tokens.managementConsole.warningToSaveTitle]:
    'Voulez-vous enregistrer vos modifications ?',
  [tokens.managementConsole.warningToSaveConfirmButtonText]: 'Enregistrer',
  [tokens.managementConsole.warningToSaveCancelButtonText]:
    'Quitter sans enregistrer',
  //OrganizationSettings section
  [tokens.managementConsole.organizationSettingsUpdated]:
    "Paramètres de l'organisation mis à jour",
  [tokens.managementConsole.issuesSettingsOptions]:
    'Erreur au chargement des clées',
  [tokens.managementConsole.generalSettings]: 'Paramètres généraux',
  [tokens.managementConsole.customsPreFilling]:
    'Pré-remplissage déclarations douanières',
  [tokens.managementConsole.lockedFieldTooltip]:
    'Information non modifiable par vos équipes',
  [tokens.managementConsole.unlockedFieldTooltip]:
    'Information modifiable par vos équipes',
  [tokens.managementConsole.establishmentAndGroup]: 'Etablissement et groupe',
  [tokens.managementConsole.administrator]: 'Administrateur',
  //Establishment
  [tokens.managementConsole.establishment]: 'Établissement',
  [tokens.managementConsole.errorUpdatingSettings]:
    "Erreur à l'enregistrement des paramètres",
  [tokens.managementConsole.resetSettings]:
    'Les paramètres ont été réinitialisés',
  [tokens.managementConsole.settingsHasBeenUpdated]: 'Paramètres mis à jour',
  [tokens.managementConsole.impossibleToLockEmptField]:
    'Impossible de bloquer un champ vide',
  [tokens.managementConsole.settingOfGroup]: 'Paramètre du groupe',

  [tokens.account.generalSettings]: 'Paramètres généraux',
  [tokens.account.customsPreFilling]: 'Pré-remplissage déclarations douanières',
  [tokens.account.settingsHasBeenUpdated]: 'Paramètres mis à jour',
  [tokens.account.errorUpdatingSettings]:
    "Erreur à l'enregistrement des paramètres",
  [tokens.account.resetSettings]: 'Les paramètres ont été réinitialisés',

  [tokens.store.anErrorOccurred]: 'Une erreur est survenue',
  [tokens.store.unableToUpdateBoundingRectangle]:
    'Impossible de mettre à jour le rectangle',
  [tokens.store.targetExportFieldIsNotEmpty]:
    'Le champ cible est déjà attribué',
  [tokens.store.unableToCreateColumnSomeCellsAreInvalid]:
    'Impossible de créer la colonne - des cellules ne sont pas valides',
  [tokens.store.unableToCreateColumnTableAndColumnRowsMustBeIdentical]:
    'Impossible de créer la colonne - nombre de rangées dans le tableau et la colonne doit être identiques',
  [tokens.store.thereIsAlreadyATableOnThisPage]:
    'Il existe déjà un tableau dans cette page',
  [tokens.store.unableToProjectCellsOntoNewTable]:
    'Impossible de projeter des cellules existantes sur cette page',
  [tokens.store.unableToProjectEmptyRowOntoNewPage]:
    'Impossible de projeter une rangée vide sur une nouvelle page',
  [tokens.store.newTableIsTooSmallToProjectCellsFromExistingTable]:
    'Nouveau tableau est trop petit pour pouvoir projeter des cellules du tableau existant',
  [tokens.store.tableHasNoRows]: "Tableau n'a aucune rangée",
  [tokens.store.unableToIdentifyTheCurrentRow]:
    "Impossible d'identifier la rangée du tableau où le souris est placé",
  [tokens.store.cannotDragTableCellIntoADifferentRow]:
    'Impossible de déplacer une cellule vers une autre rangée',
  [tokens.store.tableCellTooSmallOrInverted]:
    'Cellule du tableau est trop petite ou elle a été invertie',
  [tokens.store.tableCellCannotGoOutOfItsRowVertically]:
    'Cellule du tableau ne peut pas sortir de sa rangée au niveau vertical',
  [tokens.store.tableCellGoesOutOfTableOnTheLeft]:
    'Cellule du tableau sort de son tableau par la gauche',
  [tokens.store.tableCellGoesOutOfTableOnTheRight]:
    'Cellule du tableau sort de son tableau par la droite',
  [tokens.store.tableCellGoesOutOfTableOnTheTop]:
    'Cellule du tableau sort de son tableau par la haut',
  [tokens.store.tableCellGoesOutOfTableOnTheBottom]:
    'Cellule du tableau sort de son tableau par le bas',
}
